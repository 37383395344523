<!--后台网站框架-->
<template>
  <div class="tmpl_IndexPage webDefault">
    <!--头部-->
    <comHeader :userName="userName" :isCollapse="isCollapse" @onSetCollapse="onSetCollapse"></comHeader>

    <div class="divMenuBox" id="menuBox" v-bind:style="isCollapse==true?'width:20%; max-width: 64px;':''">
      <div class="divLogo VerticalMid" style="font-weight: 900; font-size: 18px; font-family: 微软雅黑; line-height: 40px;text-align: center; height: 80px;padding-top: 10px; border-bottom: 1px solid #ccc;width:100%;">
				IOS分发管理系统
      </div>
      <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        :collapse="isCollapse" :unique-opened="true" active-text-color="#409eff" style="margin-top: -30px;">
        <div v-for="(p,key) in menus" :key="key">
          <el-submenu v-if="p.children!=null && p.children.length>0" :index="p.actionId">
            <template slot="title">
              <i :class="p.icon"></i>
              <span slot="title" v-if="isCollapse==false">{{p.name}}</span>
            </template>
            <el-menu-item-group>
              <span slot="title" v-if="isCollapse==true" style="font-size:16px;">{{p.name}}</span>
              <div v-for="(p1,key1) in p.children" :key="key1">
                <el-menu-item :index="p1.actionId" @click="onPage(p1.url)"
                  v-if="p1.children==null || p1.children.length<=0">
                  {{p1.name}}
                </el-menu-item>
                <el-submenu :index="p1.actionId" v-else>
                  <span slot="title" style="font-size: 14px;">{{p1.name}}</span>
                  <el-menu-item @click="onPage(p2.url)" v-for="(p2,key2) in p1.children" :key="key2"
                    :index="p2.actionId">{{p2.name}}</el-menu-item>
                </el-submenu>
              </div>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item :index="p.actionId" v-else @click="onPage(p.url)">
            <i :class="p.icon"></i>
            <span slot="title">{{p.name}}</span>
          </el-menu-item>
        </div>
      </el-menu>

    </div>
    <div style="float:left;margin-top: 40px;"
      :style="isCollapse==false?'margin-left: 240px;width:' + minWidth + 'px':'margin-left: 80px;width:' + maxWidth + 'px'">
      <router-view style="margin-top: 40px;" v-wechat-title="$route.meta.title" />
    </div>


    <div id="toTop" class="VerticalMid">
      <el-tooltip class="item" effect="dark" content="返回顶部" placement="top">
        <i class="el-icon-caret-top"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
  import random from '@/assets/css/Admin.css';

  import comHeader from '@/components/adminHeader.vue';

  export default {
    components: {
      comHeader,

    },
    data() {
      return {
        isCollapse: false,
        minWidth: 0,
        maxWidth: 0,
        menus: [],
        defaultActive: null,
        allMenu: [],
        userName: null,
        currentUser:
        {
          token:null,
          userName:null,
          userId:null,
          name:null,
          actions:null,
          menus:null,
        }
      };
    },
    created() {
      let that = this;

/*
      var menu = {
        url: '/admin/home/index',
        name: '工作台',
        title: '工作台',
        code: '1-1',
        children: [],
        actionId: '1-1',
        icon: 'el-icon-s-home'
      };
      that.menus.push(menu);
*/



			
			var menu = {
			  url: '/admin/Interface/Index',
			  name: '接口管理',
			  title: '接口管理',
			  code: '11-1',
			  children: [],
			  actionId: '11-1',
			  icon: 'el-icon-collection'
			};
			that.menus.push(menu);
			
			var userType = JSON.parse(localStorage.getItem("currentUser")).userType;
			if(userType==10)
			{
				menu = {
					url: '/admin/User/Index',
					name: '账号管理',
					title: '账号管理',
					code: '10-1',
					children: [],
					actionId: '10-1',
					icon: 'el-icon-user'
				};
				that.menus.push(menu);
			}


        //var url = that.$route.matched[that.$route.matched.length - 1].path.toLowerCase();
        that.allMenu = that.menus;
        var url = that.$route.matched[that.$route.matched.length - 1].path.toLowerCase();
        var exist = false;
        for (var i in    that.allMenu) {
          var t = that.allMenu[i];
          if (t.url != null && typeof(t.url) != "undefined" && t.url != "") {
            if (t.url.toLowerCase() == url) {
              GetDefaultActive(that, t.actionId);
            }
          }
        }

        isPower(that);

        if (that.defaultActive == null || that.defaultActive == "") {
          that.defaultActive = '1-1';
        }
    },
    computed: {
      routers() {
        return this.$route.path;
      }
    },
    methods: {
      onPage(url) {
        let that = this;
        if (url != null && typeof(url) != "undefined" && url != "") {
          that.$router.push({
            path: url,
          });
        }
      },
      handleOpen(key, keyPath) {
        //console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        //console.log(key, keyPath);
      },
      onSetCollapse(val) {
        let that = this;
        that.isCollapse = val;
      }
    },
    mounted() {
      let that = this;
      that.maxWidth = document.body.clientWidth - 100;
      that.minWidth = document.body.clientWidth - 260;
      $(window).scroll(function() {
        if ($(this).scrollTop() != 0) {
          $("#toTop").fadeIn();
        } else {
          $("#toTop").fadeOut();
        }
      });

      $("#toTop").click(function() {
        $("body,html").animate({
          scrollTop: 0,
        }, 800);
      });



    },
    watch: {
      $route() {
        let that = this;
        isPower(that);
        that.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      },
    },
  };

  // 生成菜单
  function RecursionMenu(that, menus) {
    for (var it of menus) {
      //if(it.url!=null && it.url.toLowerCase()=="/admin/score")
      if (it.code != null && (it.code.toLowerCase() == "score_adviser" || it.code.toLowerCase() ==
        "dormitory_manage")) {
        // if (it.code != null) {
        if (it.children != null && typeof(it.children) != "undefined" && it.children.length > 0) {
          it.url = it.children[0].url;
        } else {
          var actions = that.currentUser.actions;
          for (var action of actions) {
            if (action.parentId == it.actionId) {
              it.url = action.url;
              break;
            }
          }
        }
      } else {
        if (it.children != null && typeof(it.children) != "undefined" && it.children.length > 0) {
          RecursionMenu(that, it.children);
        }
      }
    }
  }

  //判断页面是否有权限
  function isPower(that) {
    var url = that.$route.matched[that.$route.matched.length - 1].path.toLowerCase();
    var exist = false;
    if(url!="/admin/home/index" && url != "/admin/action/index")
    {
      for (var i in that.allMenu) {
        if (that.allMenu[i].url != null && typeof(that.allMenu[i].url) != "undefined" && url == that.allMenu[i].url
          .toLowerCase()) {
          exist = true;
        }
      }
    }
    else{
      exist = true;
    }

/*
    if (exist == false) {
      var backUrl = that.$route.query.url;
      if (backUrl == null || typeof(backUrl) == "undefined" || backUrl == "") {
        backUrl = "/Admin/home/index";
      }

      warning(that, "该页面权限您没有得到授权，请与管理员联系！");
      setTimeout(function() {
        that.$router.push({
          path: backUrl,
        });
        return false;
      }, 1000);

    }*/
  }

  //获得默认的菜单
  function GetDefaultActive(that, id) {
    for (var i in that.allMenu) {
      if (that.allMenu[i].actionId == id) {
        if (that.allMenu[i].isMenu == true) {
          that.defaultActive = id;
        } else {
          if (that.allMenu[i].parentId != null) {
            GetDefaultActive(that, that.allMenu[i].parentId);
          }
        }
      }
    }
  }
</script>

<style scoped="scoped">
  #toTop {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 99;
    display: none;
    padding: 2px 8px;
    cursor: pointer;
    background-color: #F7F7F7;
    color: #444444;
    border: 1px solid #DDDDDD;
    font-size: 26px;
    border-radius: 5px;
  }

  #toTop:hover {
    background-color: #007BFF;
    color: #FFFFFF;
    border-color: #007BFF;
  }

  .box-card {
    margin-bottom: 16px;
  }

  .divMenuBox {
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 13.75rem;
    /*220px*/
    min-height: 43.75rem;
    /*700px*/
    background-color: #FFFFFF;
    z-index: 2;
    -moz-box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, .25), 0 0.625rem 0.625rem rgba(0, 0, 0, .22) !important;
    /*0 14px 28px | 0 10px 10px*/
    -webkit-box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, .25), 0 0.625rem 0.625rem rgba(0, 0, 0, .22) !important;
    /*0 14px 28px | 0 10px 10px*/
    box-shadow: 0 0.875rem 1.75rem rgba(0, 0, 0, .25), 0 0.625rem 0.625rem rgba(0, 0, 0, .22) !important;
    /*0 14px 28px | 0 10px 10px*/

  }

  .divMenuBox .divLogo {
    height: 3.75rem;
  }
</style>
